import styled from 'styled-components';

interface DividerProps {
    readonly width?: string;
    readonly color?: string;
    readonly thin?: boolean;
    readonly centered?: boolean;
}

export default styled.hr.attrs({
    role: 'separator'
})<DividerProps>`
    ${({ thin }) => (thin ? 'border-bottom-width: 0' : `border: 1px solid;`)};
    border-color: ${(props) => props.color || props.theme.promotedSection.colors.primary};
    width: ${({ width }) => width || '100%'};
    ${({ centered }) => (centered ? 'margin: 0 auto;' : 'margin-left: 0px;')}
`;
