import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { CameraOutlined } from '@ant-design/icons';
import Image from 'components/Image';
import Divider from 'components/Divider';

const HitboxContainer = styled.div`
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.primaryBackground};
`;

const ImageContainer = styled.div`
    position: relative;
    overflow: hidden;
    width: 90%;
    margin: 0 auto -25px auto;
`;

const ImageOverlay = styled.div`
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 99.5%;
    background: rgba(102, 102, 102, 0.5);
    text-align: center;
    font-size: 24px;
    color: white;
    text-shadow: black 0px 0px 10px;
`;

const Title = styled.h5`
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 35px 0 25px;
    margin: 0;
`;

const GalleryTitle = styled.h2`
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: white;
    padding: 20px 0;
    margin-bottom: 0;
    text-shadow: black 0px 0px 10px;
    @media (max-height: 992px) {
        font-size: 22px;
        line-height: 1.3;
        padding: 15px 0;
    }
`;

const Category = styled.h3`
    a {
        :hover {
        }
        color: white;
    }
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: white;
    text-align: center;
    text-shadow: black 0px 0px 10px;
    @media (max-width: 992px) {
        font-size: 20px;
    }
`;

const Content = styled.div`
    width: 100%;
`;

const Text = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 992px) {
        width: 95%;
    }
`;

const CountWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    color: #ffffff;
    background: rgba(126, 126, 126, 0.7);
    padding: 6px 10px 4px 7px;
    z-index: 1;
    font-size: 14px;
`;

interface Props {
    gallery: Gallery;
}

const MainHitbox = ({ gallery }: Props): JSX.Element => (
    <HitboxContainer>
        <Title>Polecamy</Title>
        <ImageContainer>
            <Image
                type="index"
                alt={gallery.title}
                src={gallery.images[0]}
                width={806}
                height={495}
                style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }}
            />
            <ImageOverlay>
                <Text>
                    {gallery.category && (
                        <>
                            <Link href={`/${gallery.category.seoSlug}`}>
                                <Category>{gallery.category.title}</Category>
                            </Link>
                            <Divider width="100%" style={{ margin: '0 auto' }} />
                        </>
                    )}
                    <Content>
                        <Link href={`/galerie/${gallery.seoSlug}`}>
                            <GalleryTitle>{gallery.title}</GalleryTitle>
                        </Link>
                    </Content>
                </Text>
                <CountWrapper>
                    <CameraOutlined /> {gallery.images.length}
                </CountWrapper>
            </ImageOverlay>
        </ImageContainer>
    </HitboxContainer>
);

export default MainHitbox;
